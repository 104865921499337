@import url('https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@400;500;600;700;800&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-size: 16px;
  --color-text: #000;
  --color-bg: #000;
  --color-bgc: #fff;
  --color-header: rgba(0, 0, 0, 0.9);
  --color-blk: #fff;
  --color-link: #fff;
  --color-link-hover: #907030;
  --color-title: #907030;
  --perspective: 1500px;
  --grid-item-ratio: 0.7071;
  --grid-width: 100%;
  --grid-height: auto;
  --grid-gap: 2vw;
  --grid-columns: 4;
  --grid-inner-scale: 1;

}


* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

canvas {
  display: block;
}

html {
  scroll-behavior: initial;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-bgc);
  overflow-x: hidden;
  font-family: 'Wix Madefor Display', sans-serif;
  position: relative;
  /* cursor: none; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #444;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #FF471E;

}

a {
  outline: none;
  /* cursor: none; */
}

/* Page Loader */
.loading::before,
.loading::after {
  content: '';
  position: fixed;
  z-index: 1000;
}

.loading::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-bg);
}

.loading::after {
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
  border-radius: 50%;
  opacity: 0.4;
  background: var(--color-link);
  animation: loaderAnim 0.7s linear infinite alternate forwards;

}

@keyframes loaderAnim {
  to {
    opacity: 1;
    transform: scale3d(0.5, 0.5, 1);
  }
}


[data-cursor] {
  width: 10px;
  height: 10px;
  /* background: linear-gradient(120deg, #FF471E, #1ed9ff, #ff471e); */
  background: #0bd6ff;
  background-size: 1600% 1600%;
  mix-blend-mode: difference;
  pointer-events: none;
  z-index: 999999999;
  transition: 0.1s linear;
  animation: blobBackground 15s ease infinite;
  border-radius: 50%;
}

@keyframes blobRadius {

  0%,
  100% {
    border-radius: 43% 77% 80% 40%/40% 40% 80% 80%;
  }

  20% {
    border-radius: 47% 73% 61% 59%/47% 75% 45% 73%;
  }

  40% {
    border-radius: 46% 74% 74% 46%/74% 58% 62% 46%;
  }

  60% {
    border-radius: 47% 73% 61% 59%/40% 40% 80% 80%;
  }

  80% {
    border-radius: 50% 70% 52% 68%/51% 61% 59% 69%;
  }
}

@keyframes blobBackground {

  0%,
  100% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }
}

.cursor-hover--a [data-cursor] {
  width: 40px;
  height: 40px;
  animation: blobRadius 5s ease infinite;
  background: #eee;

}